.Customers {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.Customers__box {
  margin: 0 0 auto 100px;
}

.Customers__span {
  padding-left: 20px;
  display: block;
}

@media (max-width: 576px) {
  .Customers__box {
    margin: 0 0 auto 20px;
  }
}
