.spanContainer {
  text-align: center;
  margin: 0 0 20px 0;
}
.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  display: block;
  margin: 20px 0 0 0;
}
.lineBreak {
  margin-bottom: 5px;
  font-size: 14px;
}
.logo {
  width: 70px;
  height: 70px;
}
.img {
  width: 100%;
  height: 380px;
  object-fit: scale-down;
}
@media (max-width: 900px) {
  .img {
    height: 300px;
  }
}
