.StagesFormation {
  text-align: left;
  margin: 100px 0;
}

.StagesFormation__stage {
  margin: 25px auto;
  text-align: start;
  padding: 25px;
  max-width: 600px;
  margin-top: 25px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 50px;
  align-items: center;
}

.StagesFormation__Icon {
  width: 50px !important;
  height: 50px !important;
  padding: 15px;
  border-radius: 50%;
  background-color: #62caec;
  color: #385b95;
}

@media (max-width: 576px) {
  .StagesFormation__stage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .StagesFormation__stage:nth-of-type(2n) {
    flex-direction: column-reverse;
  }
}
