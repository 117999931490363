.App {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}
.wrapper{
  padding: 0px 47px;
}
@media (max-width:600px) {
  .wrapper{
    padding: 0px 30px;
  }
}