.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  display: block;
  margin: 20px 0 0 0;
}
.list {
  text-align: start;
}
.company {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 170%;
}
.link {
  color: #94a3b8;
  font-family: 'Roboto';
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  margin: 0 0 25px 0;
}
.link:hover {
  text-decoration: underline;
}
.contactLink {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none;
  color: black;
  margin: 0px 0 20px 0;
}
.contactLink:hover {
  text-decoration: underline;
}

.logoImg {
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  margin-right: auto;
}
.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.lineBreak {
  margin-bottom: 5px;
  font-size: 14px;
}
.spanContainer {
  text-align: initial;
}
@media (max-width: 400px) {
  .logoSpan {
    display: block;
  }
}

@media (max-width: 600px) {
  .spanContainer {
    text-align: center;
  }
  .company {
    text-align: center;
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}
@media (max-width: 900px) {
  .spanContainer {
    text-align: center;
  }
  .logo {
    flex-direction: column;
    align-items: center;
    margin: 0 auto 50px auto;
  }
  .list {
    padding: 0;
  }
  .company {
    text-align: start;
  }
}
