.AboutCompany {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 40px;
  text-align: left;
  align-items: center;
}

.AboutCompany__swiper {
  grid-column: 2/2;
  grid-row: 1/5;
}
@media (max-width: 992px) {
  .AboutCompany {
    grid-template-columns: 3fr 1fr;
    gap: 20px 40px;
  }
}
@media (max-width: 895px) {
  .AboutCompany {
    max-width: 400px;
    align-items: center;
    flex-direction: column;
    display: flex;
    gap: 20px;
  }
  .AboutCompany__title {
    width: 100%;
  }
}
