.textField {
  margin: 0 0 10px 0;
}

.textField > p {
  position: absolute;
  bottom: 0px;
  transform: translate(0, 100%);
}
.alertContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  font-size: 28px;
}
.alertContainer > div {
  font-size: 70px;
}
